import { css } from '@emotion/core'

export const globalStyles = css`
	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed, 
	figure, figcaption, footer, header, hgroup, 
	menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}
	/* HTML5 display-role reset for older browsers */
	article, aside, details, figcaption, figure, 
	footer, header, hgroup, menu, nav, section {
		display: block;
	}
	body {
		line-height: 1;
		margin: 0 !important;
	}
	html {
		font-family: 'Montserrat', sans-serif;
	}
	ol, ul {
		list-style: none;
	}
	blockquote, q {
		quotes: none;
	}
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	a {
		text-decoration: none;
	}
	/* TABS */
	.react-tabs__tab-list {
		border-bottom: 1px solid #E4EAEE;
		padding: 0;
		display: flex;
		font-size: 14px;
		color: #CCCCCC;
		height: auto;
		display: flex;
	}

	.react-tabs__tab {
		display: block;
		border-top: none;
		border-right: none;
		border-left: none;
		text-align: center;
		bottom: -1px;
		position: relative;
		list-style: none;
		padding: 25px 12px;
		cursor: pointer;
		width: 100%;
		&:not(:has('.react-tabs__tab--disabled')):hover {
			color: #0770CF;
		}
	}
	.react-tabs__tab:focus {
		border: none;
		box-shadow: none;
		border-bottom: 1px solid #0770CF;
		outline: none;
	}
	.react-tabs__tab:focus::after {
		height: 0!important;
	}
	.react-tabs__tab--selected {
		background: #fff;
		color: #0770CF;
		font-weight: bold;
		border-bottom: 1px solid #0770CF;
	}

	.react-tabs__tab--disabled {
		color: GrayText;
		cursor: default;
	}

	.react-tabs__tab:focus:after {
		content: "";
		position: absolute;
		height: 5px;
		left: -4px;
		right: -4px;
		bottom: -5px;
		background: #fff;
	}

	.react-tabs__tab-panel {
		display: none;
	}

	.react-tabs__tab-panel--selected {
		display: block;
	}
		/**
	* ----------------------------------------------
	* Demo styles
	* ----------------------------------------------
	**/
	.accordion {
			border: 0px solid rgba(0, 0, 0, 0.1);
			border-radius: 2px;
			width: 100%;
	}

	.accordion__item + .accordion__item {
			border-top: 1px solid rgba(0, 0, 0, 0.1);
	}

	.accordion__button {
			background-color: #F1F0F0;
			cursor: pointer;
			padding: 24px;
			text-align: left;
			border: none;
			color: #004D80;
			font-size: 16px;
			letter-spacing: 0.23px;
			line-height: 30px;
			font-weight: 600;
			outline: none;
	}

	.accordion__button h3 {
		color: #2A323C !important;
		font-size: 18px !important;
		letter-spacing: 0.34px;
		line-height: 29px;
		display: -webkit-inline-box;
	}

	.accordion__button:hover {
			background-color: #fff;
	}

	.accordion__button:before {
			display: inline-block;
			/* content: ''; */
			height: 8px;
			width: 8px;
			margin-right: 12px;
			border-bottom: 2px solid currentColor;
			border-right: 2px solid currentColor;
			transform: rotate(-45deg);
	}

	.accordion__button[aria-expanded='true']::before,
	.accordion__button[aria-selected='true']::before {
			transform: rotate(45deg);
	}

	.accordion__panel {
		padding: 20px;
		animation: fadein 0.35s ease-in;
		text-align: left;
	}

	.accordion__panel span {
		color: #171515;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03px;
		margin: 0 auto;
		text-align: left;
	}

	/* -------------------------------------------------- */
	/* ---------------- Animation part ------------------ */
	/* -------------------------------------------------- */

	@keyframes fadein {
			0% {
					opacity: 0;
			}

			100% {
					opacity: 1;
			}
	}

	:target::before {
		content: '';
		display: block;
		height: 123px;
		margin: -123px 0 0;
	}
	/* TABS */
	.react-tabs {
		-webkit-tap-highlight-color: transparent;
		width: 900px;

		&__tab-list {
			border-bottom: 1px solid #0095FF;
			margin: 0 0 10px;
			padding: 0;
		}

		&__tab {
			display: inline-block;
			border: 1px solid #0095FF;
			border-bottom: none;
			bottom: -1px;
			position: relative;
			list-style: none;
			padding: 14px 12px;
			cursor: pointer;
			color: #0095FF;
			font-size: 16px;
			font-weight: 600;
			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
			letter-spacing: 0.23px;
			line-height: 19px;

			&--selected {
				background: #0095FF;
				border: 1px solid #0095FF;
				color: #FFFFFF;
				text-transform: uppercase;
				padding: 14px 0;
				border-radius: 0;
			}

			&--disabled {
				color: GrayText;
				cursor: default;
			}

			&:focus {
				box-shadow: 0 0 5px hsl(208, 99%, 50%);
				border-color: hsl(208, 99%, 50%);
				outline: none;

				&:after {
					content: "";
					position: absolute;
					height: 5px;
					left: -4px;
					right: -4px;
					bottom: -5px;
					background: #fff;
				}
			}
		}

		&__tab-panel {
			display: none;

			&--selected {
				display: block;
			}
		}
	}

`