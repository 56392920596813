import { css } from '@emotion/core'

export const userTerms = css`
  h1 {
    color: #222222;
    font-size: 24px;
    font-weight: 900;
    line-height: 55px;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 24px;
  }
  h2 {
    color: #222222;
    font-size: 18px;
    font-weight: 900;
    line-height: 55px;
  }
  p {
    color: #3D3E40;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 20px;
    margin-bottom: 24px;
    text-align: justify;
  }
`